import React, { createContext, useEffect, useState } from 'react';

const defaultState = {
  flashData: [],
  data: [],
  log: '',
  logs: []
};

export const AppContext = createContext(defaultState);

export const AppContextProvider = ({ children }) => {
  const [data, setData] = useState(defaultState.data);
  const [log, setLog] = useState(defaultState.log);
  const [logs, setLogs] = useState(defaultState.logs);
  const [progress, setProgress] = useState(0);
  const [flashData, setFlashData] = useState(defaultState.flashData);

  useEffect(() => {
    setLogs([...logs, log]);
  }, [log, flashData]);

  const clearLogs = () => {
    setLogs([]);
  };

  const formContextData = {
    flashData,
    setFlashData,
    data,
    logs,
    log,
    setLog,
    setLogs,
    clearLogs,
    setProgress,
    progress
  };

  return (
    <AppContext.Provider value={formContextData}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
