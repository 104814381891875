import { createSlice, current } from '@reduxjs/toolkit';
import {DIGITALINPUTESP32, ESP32PIN, GPIO, GPIOINPUT} from '../../constant/formConstant';

const initialState = {
  deviceData: [],
  selectedItems: [],
  availableGPIOs: ESP32PIN,
  availableInputGPIOs: DIGITALINPUTESP32,
  GPIO_KEYS: [
    'gpio',
    'gpio_out',
    'gpio_in',
    'gpio_sck',
    'gpio_cs',
    'gpio_sda',
    'gpio_scl',
    'gpio_miso',
    'gpio_mosi'
  ],
  usedGPIOs: [],
  openForm: false,
  deviceForm: false,
  isConnected:false,
  deviceGetConfig:false,
  isReset:false,
  isFactorReset:false,
  isDisconnected:false,
  deviceFlashLoading:false,
  deviceLinkLoading:false,
  deviceConfigLoading:false,
  deviceInfoLoading:false,
  resetLoading:false,
  factorResetLoading:false,
  resetDevice:false,
  isDeviceInfo:false,
  isFlash:false,
  isLinkDevice:false,
  isCoreFlash:false,
  deviceConfig:'',
  deviceConfigurationTitle:'',
  deviceFormSteps:0,
  loginJwt: '',
  selectedChip: 'esp32',
  deviceStatus: false,
  deviceError: '',
  deviceCMDOne: null,
  deviceCMDFour: null,
  deviceCMD: null,
  deviceTerminal: null,
  deviceLog:[],
  deviceMessage:'',
  deviceMessageType:'',
  terminalStatus: false,
  ezloFirmwareStatus: false,
  ezloFirstFirmwareStatus: false,
  ezloFirstEnrollStatus: false,
  ezloFirstEnrollMac: '',
  ezloFirstEnrollSerial: '',
  ezloEnrollStatus: false,
  firmwareType: 'base',
  environmentType: 'production',
};

export const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setFlashedDeviceData: (state, action) => {
      state.deviceData = action.payload;
    },
    setDeviceData: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.deviceData = action.payload;
        return;
      }
      let arr = [];
      
      if (state.deviceData.length && !action?.payload?.isDeleted ) {
        if (state.deviceData.length === 0 ) {
          state.deviceData = arr;
        }else {
          state.deviceData = [...state.deviceData, action.payload];
        }
      }
      else if (state.deviceData.length && action.payload.isDeleted) {
        state.deviceData = action.payload?.newConfig; //...state.deviceData,
      }
      
      else if (!state.deviceData.length && !action.payload.length) {
        arr.push(action.payload);
        state.deviceData = arr;
      } else {
        state.deviceData = action.payload;
      }
      
    },
    
    setSelectedItem: (state, action) => {
      const itemId = action.payload;
      state.selectedItems.push(itemId);
      console.log(current(state.selectedItems));
    },
    removeSelectedItem: (state, action) => {
      const itemId = action.payload;
      // remove item if exists
      state.selectedItems = state.selectedItems.filter(
        (selectedItem) => selectedItem != itemId
      );
    },
    // removeDeviceData: (state) => {
    //   const removefilter = state.deviceData.filter((item) => {
    //     if (state.selectedItems !== item.id) {
    //       const avail_able = state.availableGPIOs;
    //       Object.keys(item).forEach((key) => {
    //         if (item[key]) {
    //           if (state.GPIO_KEYS.includes(key)) {
    //             if (state.selectedItems.includes(item.id)) {
    //               avail_able.push(item[key]);
    //             }
    //           }
    //         }
    //       });
    
    //       state.availableGPIOs.sort((a, b) => +a - +b);
    //       return !state.selectedItems.includes(item.id);
    //     }
    //   });
    //   state.deviceData = removefilter;
    //   state.selectedItems = [];
    // },
    removeDeviceData: (state) => {
      const removefilter = state.deviceData.filter((item) => {
        if (state.selectedItems != item.id) {
          // const avail_able = state.availableGPIOs;
          if (item.gpio) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio);
            }
          }
          if (item.gpio_in) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_in);
            }
          }
          if (item.gpio_out) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_out);
            }
          }
          if (item.gpio_sck) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_sck);
            }
          }
          if (item.gpio_cs) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_cs);
            }
          }
          if (item.gpio_miso) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_miso);
            }
          }
          if (item.gpio_mosi) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_mosi);
            }
          }
          if (item.gpio_sda) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_sda);
            }
          }
          if (item.gpio_scl) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_scl);
            }
          }
          state.availableGPIOs.sort((a, b) => +a - +b);
          
          return !state.selectedItems.includes(item.id);
        }
      });
      state.deviceData = removefilter;
      state.selectedItems = [];
    },
    setAvailableGPIO: (state, action) => {
      state.availableGPIOs = state.availableGPIOs.filter(
        (availableGPIO) => availableGPIO != action.payload
      );
    },
    isConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    deviceCMDOne: (state, action) => {
      state.deviceCMDOne = action.payload;
    },
    deviceCMDFour: (state, action) => {
      state.deviceCMDFour = action.payload;
    },
    deviceCMD: (state, action) => {
      console.log(action.payload);
      state.deviceCMD = action.payload;
    },
    deviceTerminal: (state, action) => {
      state.deviceTerminal = action.payload;
    },
    deviceLog: (state, action) => {
      console.log(action.payload);
      state.deviceLog = [...state.deviceLog, action.payload];
    },
    ezloFirmwareStatus: (state, action) => {
      state.ezloFirmwareStatus = action.payload;
    },
    ezloFirstFirmwareStatus: (state, action) => {
      console.log(action.payload);
      state.ezloFirstFirmwareStatus = action.payload;
    },
    ezloFirstEnrollStatus: (state, action) => {
      console.log(action.payload);
      state.ezloFirstEnrollStatus = action.payload;
    },
    ezloFirstEnrollMac: (state, action) => {
      console.log(action.payload);
      state.ezloFirstEnrollMac = action.payload;
    },
    ezloFirstEnrollSerial: (state, action) => {
      console.log(action.payload);
      state.ezloFirstEnrollSerial = action.payload;
    },
    ezloEnrollStatus: (state, action) => {
      console.log(action.payload);
      state.ezloEnrollStatus = action.payload;
    },
    deviceMessage: (state, action) => {
      state.deviceMessage = action.payload;
    },
    deviceMessageType: (state, action) => {
      state.deviceMessageType = action.payload;
    },
    terminalStatus: (state, action) => {
      state.terminalStatus = action.payload;
    },
    firmwareType: (state, action) => {
      state.firmwareType = action.payload;
    },
    environmentType: (state, action) => {
      state.environmentType = action.payload;
    },
    isReset: (state, action) => {
      state.isReset = action.payload;
    },
    isFactorReset: (state, action) => {
      state.isFactorReset = action.payload;
    },
    deviceGetConfig: (state, action) => {
      state.deviceGetConfig = action.payload;
    },
    isDisconnected: (state, action) => {
      state.isDisconnected = action.payload;
    },
    deviceFlashLoading: (state, action) => {
      state.deviceFlashLoading = action.payload;
    },
    deviceLinkLoading: (state, action) => {
      state.deviceLinkLoading = action.payload;
    },
    deviceConfigLoading: (state, action) => {
      state.deviceConfigLoading = action.payload;
    },
    deviceInfoLoading: (state, action) => {
      state.deviceInfoLoading = action.payload;
    },
    resetLoading: (state, action) => {
      state.resetLoading = action.payload;
    },
    factorResetLoading: (state, action) => {
      state.factorResetLoading = action.payload;
    },
    resetDevice: (state, action) => {
      state.resetDevice = action.payload;
    },
    isDeviceInfo: (state, action) => {
      state.isDeviceInfo = action.payload;
    },
    isCoreFlash: (state, action) => {
      console.log(action.payload);
      state.isCoreFlash = action.payload;
    },
    isFlash: (state, action) => {
      console.log(action.payload);
      state.isFlash = action.payload;
    },
    isLinkDevice: (state, action) => {
      console.log(action.payload);
      state.isLinkDevice = action.payload;
    },
    formClose: (state, action) => {
      state.openForm = action.payload;
    },
    deviceFormClose: (state, action) => {
      state.deviceForm = action.payload;
    },
    deviceFormSteps: (state, action) => {
      state.deviceFormSteps = action.payload;
    },
    deviceConfig: (state, action) => {
      state.deviceConfig = action.payload;
    },
    deviceConfigurationTitle: (state, action) => {
      state.deviceConfigurationTitle = action.payload;
    },
    setLoginJwt: (state, action) => {
      state.loginJwt = action.payload;
    },
    setChipType: (state, action) => {
      state.selectedChip = action.payload;
    },
    setDeviceError: (state, action) => {
      state.deviceError = action.payload;
    },
    setDeviceStatus: (state, action) => {
      state.deviceStatus = action.payload;
    }
  }
});

export const {
  setFlashedDeviceData,
  setDeviceData,
  removeDeviceItem,
  removeSelectedItem,
  selectedData,
  setSelectedItem,
  selectedItems,
  removeDeviceData,
  setAvailableGPIO,
  isConnected,
  deviceCMDOne,
  deviceCMDFour,
  deviceCMD,
  deviceTerminal,
  deviceLog,
  ezloFirmwareStatus,
  ezloFirstFirmwareStatus,
  ezloFirstEnrollStatus,
  ezloFirstEnrollMac,
  ezloFirstEnrollSerial,
  ezloEnrollStatus,
  deviceMessage,
  deviceMessageType,
  terminalStatus,
  firmwareType,
  environmentType,
  isReset,
  isFactorReset,
  deviceGetConfig,
  isDisconnected,
  deviceFlashLoading,
  deviceLinkLoading,
  deviceConfigLoading,
  deviceInfoLoading,
  resetLoading,
  factorResetLoading,
  resetDevice,
  isDeviceInfo,
  isCoreFlash,
  isFlash,
  isLinkDevice,
  formClose,
  deviceFormClose,
  deviceFormSteps,
  deviceConfig,
  deviceConfigurationTitle,
  setLoginJwt,
  setChipType,
  setDeviceStatus,
  setDeviceError
} = deviceSlice.actions;

export default deviceSlice.reducer;
