import { useEffect, useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';

import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
// import { setLoginJwt } from 'src/redux/slices/devicesSlice';
import { setLoginJwt, setUserAuth } from 'src/redux/slices/statusSlice';
import Label from 'src/components/Label';
import {environmentType} from "../../../../redux/slices/devicesSlice";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const navigate = useNavigate();
  const user = {
    name: 'company6',
    avatar: '/static/images/avatars/thumbnail.png',
    jobtitle: 'Partner Master User'
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const StatusState = useSelector((state) => state['statusData']);
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState(0);
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSignOut = () => {
    //navigate('/');
    dispatch(setLoginJwt(''));
    dispatch(environmentType('production'));
    window.location.href = ('/');
  };
  const getUserRoleLabel = (userRole) => {
    const map = {
      0: {
        text: '',
        color: 'info'
      },
      1: {
        text: 'Mios Master User',
        color: 'secondary'
      },
      2: {
        text: 'Mios Tech Support',
        color: 'info'
      },
      3: {
        text: 'Oem Master User',
        color: 'info'
      },
      4: {
        text: 'Oem Support',
        color: 'info'
      },
      5: {
        text: 'Partner Master User',
        color: 'secondary'
      },
      6: {
        text: 'Partner Support',
        color: 'info'
      },
      7: {
        text: 'Partner Installer',
        color: 'secondary'
      },
      8: {
        text: 'Admin',
        color: 'info'
      },
      9: {
        text: 'Viewer',
        color: 'info'
      },
      10: {
        text: 'End-user',
        color: 'secondary'
      },
      11: {
        text: 'Notification-only',
        color: 'info'
      },
      12: {
        text: 'Property manager',
        color: 'info'
      },
      13: {
        text: 'Power User',
        color: 'info'
      }
    };

    const { text, color } = map[userRole ? userRole : 0];

    return <Label color={color}>{text}</Label>;
  };

  useEffect(() => {
    if (StatusState?.userAuth != null && StatusState?.userAuth != undefined && StatusState?.userAuth != '') {
      const userDetails = JSON.parse(StatusState?.userAuth);
      setUserName(userDetails?.Username);
      setUserRole(userDetails?.PK_PermissionRole);
    }
  }, [StatusState]);
  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={userName} src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{userName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {getUserRoleLabel(userRole)}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={userName} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{userName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {getUserRoleLabel(userRole)}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          {/* <ListItem button to="/management/profile/details" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItem> */}
          {/* <ListItem button to="/dashboards/messenger" component={NavLink}>
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary="Messenger" />
          </ListItem> */}
          <ListItemButton
              to="/change-password"
            // to="/management/profile/settings"
            component={NavLink}
            onClick={handleClose}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Change Password" />
          </ListItemButton>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleSignOut}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
