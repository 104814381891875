import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import deviceSlice from '../slices/devicesSlice';
import statusSlice from '../slices/statusSlice';
import fileSlice from '../slices/fileSlice';

const reducers = combineReducers({
  devicesData: deviceSlice,
  statusData: statusSlice,
  fileData: fileSlice
});

const persistConfig = {
  key: 'root',
  blacklist: ['devicesData', 'fileData'],
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export default store;
