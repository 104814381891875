import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  fileData: null
};

export const statusSlice = createSlice({
  name: 'fileData',
  initialState,
  reducers: {
    setFileData: (state, action) => {
      state.fileData = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    }
  }
});

export const { setFileData, setId } = statusSlice.actions;

export default statusSlice.reducer;
