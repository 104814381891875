import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { HashRouter } from 'react-router-dom';

import * as Sentry from "@sentry/browser";

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { Provider } from 'react-redux';

import store from './redux/store';
import AppContextProvider from './contexts';

// Initialize Sentry
/*Sentry.init({
    dsn: "https://647b5a3f7b054734af30dcffdabc31cc@glitchtip.nurd.page/3",
    release: "1.0",
    environment: "production",
});*/

ReactDOM.render(
  <HelmetProvider>
    <AppContextProvider>
      <Provider store={store}>
        <SidebarProvider>
          <HashRouter>
            <App />
          </HashRouter>
        </SidebarProvider>
      </Provider>
    </AppContextProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
