import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  loginJwt: '',
  userAuth: '',
  userDetail: '',
  expiresTimestamp: '',
  lastestTimestamp: '',
};

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setLoginJwt: (state, action) => {
      state.loginJwt = action.payload;
    },
    setUserAuth: (state, action) => {
      state.userAuth = action.payload;
    },
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setExpiresTimestamp: (state, action) => {
      state.expiresTimestamp = action.payload;
    },
    setLastestTimestamp: (state, action) => {
      state.lastestTimestamp = action.payload;
    }
  }
});

export const { setLoginJwt, setUserAuth, setUserDetail,setExpiresTimestamp,setLastestTimestamp } = statusSlice.actions;

export default statusSlice.reducer;
