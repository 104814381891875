import { Suspense, lazy } from 'react';
import { Navigate, Routes, Route, Link } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import { useSelector, useDispatch } from 'react-redux';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));
const Auth = Loader(lazy(() => import('src/content/auth')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const ProductDevelopment = Loader(
  lazy(() => import('src/content/productDevelopment'))
);

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);

const TestDebug = Loader(lazy(() => import('src/content/testDebug')));
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const ResetPassword = Loader(
  lazy(() => import('src/components/ResetPassword'))
);
const ChangePassword = Loader(
  lazy(() => import('src/components/ChangePassword'))
);

const Version = Loader(
    lazy(() => import('src/components/Version'))
);
// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const ProtectedRoute = ({ redirectPath = '/', children }) => {
  const userToken = useSelector((state) => state['statusData']);

  if (!userToken['loginJwt']) {
    return <Auth />;
  }

  return children;
};

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <Navigate to="dashboards/productdev" replace />
          </ProtectedRoute>
        )
      },
      // {
      //   path: 'overview',
      //   element: <Navigate to="/" replace />
      // },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      // {
      //   path: 'auth',
      //   children: [
      //     {
      //       path: '',
      //       element: (
      //         <ProtectedRoute>
      //           <Navigate to="login" replace />
      //         </ProtectedRoute>
      //       )
      //     },
      //     {
      //       path: 'login',
      //       element: <Auth />
      //     }
      //   ]
      // },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/reset-password',
    element: <ResetPassword />
  },
  {
    path: 'dashboards',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="productdev" replace />
      },
      {
        path: 'crypto',
        element: <Crypto />
      },
      {
        path: 'messenger',
        element: <Messenger />
      },
      // {
      //   path: 'testdebug',
      //   element: <TestDebug />
      // },
      {
        path: 'productdev',
        element: <ProductDevelopment />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/change-password',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <ChangePassword />
      }
    ]
  },
  ,
  {
    path: '/version',
    element: (
        <ProtectedRoute>
          <SidebarLayout />
        </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Version />
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
